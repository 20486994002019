/*
	Form initialization.
	ACCEPTS: Form element, with structure:
	<[any] class="table-content">

		<form class="filters">
			<[any] class="filtergroup">
				<[input|select|textarea] class="filter" type=[radio]>
			</[any]>
		</form>

	</[any]>
 */

let timeoutHandle;

class Form {
	constructor(element) {

		this.element = element.hasClass('filters') || element.is('form') ? element : undefined;
		if (!this.element) {
			Toastr.debug('No form')
			return 0;
		};

		this.reset = this.element.find('.filtersReset').length ? this.element.find('.filtersReset') : undefined;
		if(!this.reset) Toastr.debug('Form has no global reset button');

		this.single_reset = this.element.find('.filterReset').length ? this.element.find('.filterReset') : undefined;
		if(!this.single_reset) Toastr.debug('Form has no local reset button');

		this.show = this.element.find('.filtersShow').length ? this.element.find('.filtersShow') : undefined;
		if(!this.reset) Toastr.debug('Form has no global show button');

		this.parent = this.element.closest('.table-content').length ? this.element.closest('.table-content') : undefined;
		if (!this.parent) Toastr.debug('Form serves no reason');

		this.portlet = this.element.find('.filtersForm').length ? this.element.find('.filtersForm') : undefined;
		if (!this.portlet) Toastr.debug('No main filter portlet');

		this.filtergroups = $('.filtergroup', this.element);
		if (!this.filtergroups.length) Toastr.debug('No filter groups');

		let filters = 0;
		for (let i = 0; i < this.filtergroups.length; i++) filters += $('.filter', this.filtergroups[i]).length;
		if (!filters) Toastr.debug('No filters in filtergroups');

		let all_filters = $('.filter', this.element).length;
		if (filters !== all_filters) Toastr.debug('Form has filters not in filtergroups');

		this.loaded = this.element.data('loaded') || false;

		if (!this.loaded) {
			this.loaded = this.init();
			if (this.loaded) {
				this.element.data('loaded', true);
			} else {
				return Toastr.debug('Form initialization failed');
			}
		}

		return this.loaded;
	}

	init() {
		const proto = this;

		this.element.plugin();

		if(this.reset) {
			this.reset.on('click', event => {
				event.preventDefault();
				// this.element.find()
				this.element.find('input[type=checkbox]:checked').attr('checked', false).trigger("change");
				this.element.find('input[type!=radio][type!=checkbox][type!=hidden]').val('').trigger("change");
				this.element.find('input[type=radio]:checked').prop('checked', false);
				this.element.find('.select2').val('').trigger("change");
				this.element.find('.filterReset').hide();
				// this.element.trigger('reset').find('.select2').trigger("change");
				// this.element.find('.filterReset').hide();
				LocalStorage.clear();
			});
		}

		if(this.single_reset) {
			for(const btn of this.single_reset) {
				$(btn).on('click', function(event) {
					event.preventDefault();
					proto.resetValue($(this).closest('.filtergroup'));
				});
			}
		}

		// if (!$('.portlet-body', proto.element).find('input').length) {
		// 	$(".filtersShow", proto.element).hide();
		// }
		// TODO: hide button properly and in constructor?

		this.fillFromLocalStorage();
		this.fillFromQuery();

		this.element.on('change', 'input,select', function() {
			if ($(this).val()) {
				$(this).closest('.filtergroup').find('.filterReset').show();
			}

			const val = Number($('input[name=itid]').val());
			const name = $(this).attr("name");
			if (val && name && name !== 'search_context') {
				const storage_name = `${val}.${name}`;
				if ($(this).val()) {
					if ($(this).hasClass('date-range')) {
						localStorage[storage_name] = `${ $(this).closest('.filtergroup').find('.date-range.start').val() } - ${ $(this).closest('.filtergroup').find('.date-range.end').val() }`;
					}else if ($(this).hasClass('filter_number')){
						localStorage[storage_name] = `${ $(this).closest('.filtergroup').find('.filter_number.start').val() } - ${ $(this).closest('.filtergroup').find('.filter_number.end').val() }`;
					} else {
						localStorage[storage_name] = $(this).val();
					}
				} else {
					localStorage.removeItem(storage_name);
				}
			}
		});

		this.element.on("select2-selecting", function() {
			$(this).closest('.filtergroup').find('.filterReset').show();
		});

		this.countFilters();

		$('input, .select2', proto.element).on('change', () => this.countFilters());

		$(".searchBtn", proto.container).on('click', event => {
			event.preventDefault();
			this.parent.trigger('update');
		});

		$(".filtersShow", this.element).on('click', event => {
			event.preventDefault();
			if(this.portlet.css("display") === "none") {
				this.portlet.slideDown(500);
			} else {
				this.portlet.slideUp(500);
			}
		});

		$(".filtersHide", this.element).on('click', event => {
			event.preventDefault();
			if(this.portlet.css("display") === "none") {
				this.portlet.slideDown(500);
			} else {
				this.portlet.slideUp(500);
			}
		});

		return true;
	}
	fillFromQuery() {
		const search = decodeURI(window.location.search); //?q=1&sort=1
		if (search.length) {
			search.substr(1).split('&').forEach(param => {
				const [key, value] = param.split('=');
				if (!key && key.length) return;
				const matches = $(`[name=${key}]`);
				const regex = new RegExp(`${key}.*?(&|$)`);

				if (matches.length) {
					if (matches.attr('type') === 'text' && typeof(window.history.replaceState) === 'function') {
						window.history.replaceState({}, '', window.location.href.replace(regex, ''));
						matches.val(value);
					}
				}
				// if (key === 'create_link' || (key === 'object_link_id') && typeof(window.history.replaceState) === 'function') {
				// 	window.history.replaceState({}, '', window.location.href.replace(regex, ''));
				// }
			});
		} else if (typeof(window.history.replaceState) === 'function')  {
			window.history.replaceState({}, '', window.location.href.replace('?', ''));
		}
	}

	fillFromLocalStorage() {
		const queriesKeys = ['object_link_id'];
		const test = location.search
			.replace('?', '')
			.split('&')
			.map( e => e.split('=') )
			.filter( q => queriesKeys.some( el => el === q[0]) ).length;

		if (test) { return; }

		const form = this.element;
		for(let i in localStorage) {
			const regexp = /(\d+)\.(.+)/;
			const match = regexp.exec(i);
			if(match !== null) {
				if(Number(match[1]) === Number($('input[name=itid]').val())) {
					const input = form.find(`input[name=${match[2]}]`);
					if(input.length) {
						if(input.attr('type') === 'radio') {
							input.each(function() {
								if($(this).attr('value') === localStorage[i]) {
									$(this).prop('checked','checked');
								}
							});
						} else if(input.attr('type') === 'checkbox')  {
							$(input).prop('checked','checked');
						} else if(input.hasClass('filter_number'))  {
							const input_regex = /([0-9]+)\s-\s([0-9]+)/;
							const number_match = input_regex.exec(localStorage[i]);
							input.closest('.filtergroup').find('.start').val(number_match && number_match[1]);
							input.closest('.filtergroup').find('.end').val(number_match && number_match[2]);
						} else {
							const date_regex = /(\d{2}\.\d{2}\.\d{4})?\s-\s(\d{2}\.\d{2}\.\d{4})?/;
							const date_match = date_regex.exec(localStorage[i]);
							if(date_match !== null && (date_match[1] !== null || date_match[2] !== null)) {
								if(date_match[1]) {
									input.closest('.filtergroup').find('.start').val(date_match[1]);
								}
								if(date_match[2]) {
									input.closest('.filtergroup').find('.end').val(date_match[2]);
								}
							} else {
								input.val(localStorage[i]);
							}
							input.closest('.filtergroup').find('.filterReset').show();
						}
					}

					const select = form.find(`select[name=${Util.escapeSelector(match[2])}]`);
					if(select.length) {
						const array = localStorage[i].split(',');
						for (let i of array) {
							if (!i) continue;
							$(select).append($("<option>", {
								value: i,
								text: i,
								selected: true
							}));
						}
						select.closest('.filtergroup').find('.filterReset').show();
					}

				}
			}
		}
	}


	countFilters() {
		let filters = $('input[type!=radio][type!=checkbox][type!=hidden][name!=search_context]', this.element).filter(function() {
			return this.value.length > 0;
		}).length + $('input[type=radio]:checked, input[type=checkbox]:checked', this.element).length;

		$('.select2', this.element).each(function() {
			const val = $(this).val();
			if (!val) return;
			if ( Array.isArray(val) ){
				if ($(this).val().filter( e => e !== '').length) {
					filters += 1;
				}
			} else {
				filters += 1;
			}
		});

		if(filters) {
			$('.filtersShow', this.element).removeClass('btn-default').addClass('btn-success').html('<i class="fa fa-filter"></i> Фильтры (' + filters + ')');
		} else {
			$('.filtersShow', this.element).removeClass('btn-success').addClass('btn-default').html('<i class="fa fa-filter"></i> Фильтры');
		}
	}

	resetValue(filtergroup) {
		filtergroup.find('input:not([type=radio])').attr('checked',false).trigger("change");
		filtergroup.find('input:not([type=radio])').val('').trigger("change");
		filtergroup.find('input[type=radio]').prop('checked', false).trigger("change");
		filtergroup.find('.select2').val('').trigger("change");
		filtergroup.find('.filterReset').hide();
		if(filtergroup.reset) {
			filtergroup.reset();
		}
		LocalStorage.remove($('input[name=itid]', this.element).val() + '.' + filtergroup.find('input[type=radio]', this.element).attr('name'));
	}

}

$.fn.form = function () {
	const form = new Form(this);
	if (form.error) {
		Toastr.debug(form.error, 'Form initialization error');
		// throw new Error(form.error);
		console.log(form.error);
	}
	return form;
};
//
//
// UniForm.prototype.initChartForm = function() {
// 	if(!this.uniform_init) {
// 		var proto = this;
// 		var form = proto.element;
// 		form.plugin().initAll();
// 		form.find('.filtersReset').eq(0).click(function(event) {
// 			event.preventDefault();
// 			// form.find()
// 			form.find('input[type=radio]:checked, input[type=checkbox]:checked').attr('checked',false).trigger("change");
// 			form.find('input[type!=radio][type!=checkbox][type!=hidden]').val('').trigger("change");
// 			form.find('.select2').val('').trigger("change");
// 			form.find('.filterReset').hide();
// 			// form.trigger('reset').find('.select2').trigger("change");
// 			// form.find('.filterReset').hide();
// 			LocalStorage.clear();
// 		});
//
// 		this.element.find('.filterReset').each(function(event) {
// 			var btn = $(this);
// 			btn.click(function(event) {
// 				event.preventDefault();
// 				proto.resetValue($(this).closest('.filtergroup'));
// 			});
// 		});
//
// 		if (!$('.portlet-body', form).find('input').length) {
// 			$(".filtersShow", form).hide();
// 		}
//
// 		this.fillFromLocalStorage();
//
// 		form.on('change', 'input,select', function() {
// 			if($(this).val()) {
// 				$(this).closest('.filtergroup').find('.filterReset').show();
// 			}
//
// 			if($('input[name=itid]').val() && $(this).attr("name") && $(this).attr("name") !== 'search_context') {
// 				var name = $('input[name=itid]').val() + '.' + $(this).attr("name");
// 				if($(this).val()) {
// 					if($(this).hasClass('date-range')) {
// 						localStorage[name] = $(this).closest('.filtergroup').find('.date-range.start').val() + ' - ' + $(this).closest('.filtergroup').find('.date-range.end').val();
// 					} else {
// 						localStorage[name] = $(this).val();
// 					}
// 				} else {
// 					localStorage.removeItem(name);
// 				}
// 			}
// 		});
//
// 		form.on("select2-selecting", function(e) {
// 			$(this).closest('.filtergroup').find('.filterReset').show();
// 		});
//
// 		proto.countFilters();
//
// 		$('input, .select2', proto.element).change(function() {
// 			proto.countFilters();
// 		});
//
// 		$(".searchBtn", proto.container).click(function(event) {
// 			event.preventDefault();
// 			new Chart().clickOnSubmit();
// 			$("#chart0").chart().clickOnSubmit();
// 		});
//
// 		$(".filtersShow", this.element).click(function(event) {
// 			event.preventDefault();
// 			var $filtersDocs = $('.filtersForm');
// 			if ($filtersDocs.css("display") === "none") {
// 				$filtersDocs.slideDown(500);
// 			} else {
// 				$filtersDocs.slideUp(500);
// 			}
// 		});
//
// 		form.data('uniform_init', true);
// 		this.uniform_init = true;
// 	}
// 	return this;
//
// };
//
// function UniInput(element) {
// 	if(element.prop("tagName") == 'INPUT' || element.prop("tagName") == 'SELECT' || element.prop("tagName") == 'TEXTAREA') {
// 	 this.element = element;
// 	} else {
// 		Toastr.debug('Input could not be resolved', 'error');
// 	}
// 	return this;
// }
//
// $.fn.uniinput = function() {
// 	return new UniInput(this);
// };
//
